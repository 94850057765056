import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { BaseChartComponent } from "./BaseChartComponent";
import { PolicyProvider } from "~/api/query_fns/coverage-analysis";

interface InsurancePolicyComparisonChartProps {
  showLegend?: boolean;
}

interface TransformedChartData {
  category: string;
  [provider: string]: string | number;
  [providerDescription: `${string}_description`]: string;
  [providerFormatted: `${string}_formatted`]: string;
}

const transformData = (data: PolicyProvider[]): TransformedChartData[] => {
  const transformedData: TransformedChartData[] = [];
  const features = data[0]?.features.map((f) => f.name) || [];

  features.forEach((feature) => {
    const item: TransformedChartData = { category: feature };
    data.forEach((provider) => {
      const featureData = provider.features.find((f) => f.name === feature);

      /*       console.log("Processing feature:", {
        feature,
        provider: provider.provider,
        featureData,
        value: featureData?.value,
        value_formatted: featureData?.value_formatted,
      }); */

      item[provider.provider] = featureData?.value ?? 0;
      item[`${provider.provider}_description`] = featureData?.description ?? "";
      item[`${provider.provider}_formatted`] =
        featureData?.value_formatted ?? "";

      /*       console.log("Item after assignment:", {
        provider: provider.provider,
        itemValues: {
          raw: item[provider.provider],
          formatted: item[`${provider.provider}_formatted`],
          description: item[`${provider.provider}_description`],
        },
      }); */
    });
    transformedData.push(item);
  });

  console.log("Final transformed data:", transformedData);
  return transformedData;
};

const renderTable = (
  tableData: TransformedChartData[],
  colors: string[]
): JSX.Element => {
  if (!tableData.length)
    return (
      <div className="flex h-32 items-center justify-center text-muted-foreground">
        No data available
      </div>
    );

  const providers = Object.keys(tableData[0]).filter(
    (key) =>
      key !== "category" &&
      !key.endsWith("_description") &&
      !key.endsWith("_formatted")
  );

  return (
    <div className="space-y-1 rounded-lg border bg-card">
      <div className="rounded-md">
        <Table>
          <TableHeader>
            <TableRow className="border-b transition-colors hover:bg-muted/50">
              <TableHead className="h-12 whitespace-nowrap px-4 font-semibold">
                Coverage Feature
              </TableHead>
              {providers.map((provider, index) => (
                <TableHead
                  key={provider}
                  className="h-12 whitespace-nowrap px-4 text-center font-semibold"
                  style={{
                    backgroundColor: colors[index % colors.length] + "20",
                  }}
                >
                  {provider}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow
                key={index}
                className="border-b transition-colors data-[state=selected]:bg-muted hover:bg-muted/50"
              >
                <TableCell className="p-4 font-medium">
                  {row.category}
                </TableCell>
                {providers.map((provider, colIndex) => {
                  const description = row[`${provider}_description`];
                  const hasDescription = description && description !== "";

                  return (
                    <TableCell
                      key={provider}
                      title={description}
                      className={`p-4 text-center text-muted-foreground ${
                        hasDescription ? "hover:cursor-help" : ""
                      }`}
                      style={{
                        backgroundColor:
                          colors[colIndex % colors.length] + "20",
                      }}
                    >
                      <div className="flex items-center justify-center gap-1">
                        {row[
                          `${provider}_formatted` as keyof TransformedChartData
                        ] || "—"}
                        {hasDescription && (
                          <span className="text-xs text-muted-foreground">
                            ⓘ
                          </span>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const InsurancePolicyComparisonLimitsChart: React.FC<
  InsurancePolicyComparisonChartProps
> = () => (
  <BaseChartComponent<
    PolicyProvider,
    TransformedChartData,
    TransformedChartData
  >
    title="Comparison Limits"
    chartType="artifact_comparison_limits"
    transformData={transformData}
    renderChart={(data, colors) => renderTable(data, colors)}
  />
);

export default InsurancePolicyComparisonLimitsChart;

/*
Based on the policies reviewed, here's an array of JSON objects scoring each type of coverage. Please note that these scores are subjective interpretations based on the available information, with 100 being the best possible coverage. The scores reflect factors like coverage limits, retention amounts, and the breadth of protection offered.

const data = [
  {
    subject: 'Cyber Liability',
    'Policy A': 85,
    'Policy B': 95,
    fullMark: 100,

*/
