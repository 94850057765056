import { CopilotKit, useCopilotReadable } from "@copilotkit/react-core";
import { useLoaderData } from "react-router-dom";
import {
  ArtifactFetchResponse,
  Citation,
  fetchJobReport,
  findJobReportByEmail,
  ReportType,
} from "~/api/query_fns/coverage-analysis";
import { ReportTabs } from "~/components/ReportTabs";
import { EmailReportDisclaimer } from "~/components/EmailReportDisclaimer";
import { PDFViewerDialog } from "~/components/PDFViewerDialog";
import { useState } from "react";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { useOpenReferenceDetails } from "../doc-util";
import { CustomChat } from "~/components/util-chat";
import {
  generateInitialMessage,
  generateChatContext,
  getDocumentDetails,
} from "~/utils/util-report";
import { Message } from "../components/CustomMessages";
import { fetchDocumentTexts } from "~/utils";
import { SingleDocResponseData } from "~/api/query_fns/documents";

interface LoaderParams {
  params: {
    jobId?: string;
  };
}

type FetchJobReportLoaderResponse = {
  id: string;
  from: string;
  reportName: string;
  reportKey: string;
  reportType: ReportType;
  documents: SingleDocResponseData[];
  coverageReport: string;
  followOnQuestions: string[];
  processingStatus: string;
  citations: Citation[];
  oId: string;
  artifacts?: ArtifactFetchResponse[];
};

export const loader = async ({ params }: LoaderParams) => {
  const report = await fetchJobReport({ jobId: params.jobId || "" });
  await fetchDocumentTexts(report.documents);

  return report;
};

const GenReportPublic = () => {
  const [userId, setUserId] = useState("");
  const [locked, setLocked] = useState(true);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(true);
  const [content, setContent] = useState("AccessReportContent");
  const [queryExecuted, setQueryExecuted] = useState(false);
  const [email, setEmail] = useState("");
  const [warningText, setWarningText] = useState("");
  const [reportChatMessages, setReportChatMessages] = useState<Message[]>([]);

  const appendToReportChatMessages = (newMessages: Message[]) => {
    setReportChatMessages((prevMessages) => [...prevMessages, ...newMessages]);
  };

  const {
    id,
    from,
    reportName,
    reportKey,
    reportType,
    documents,
    coverageReport,
    followOnQuestions,
    processingStatus,
    citations,
    oId,
    artifacts,
  } = useLoaderData() as FetchJobReportLoaderResponse;

  const unlock = (email: string) => {
    setWarningText("");
    setEmail(email.toLowerCase());
  };

  useEffect(() => {
    // Reset queryExecuted to false whenever the email changes
    setQueryExecuted(false);
  }, [email]); // Dependency array includes email to trigger effect when email changes

  useQuery(
    ["findJobReportByEmail", email, id],
    () => findJobReportByEmail({ email, report_email_job_id: id }),
    {
      onSuccess: (data) => {
        if (data?.unlock) {
          setUserId(data?.userId);
          setLocked(false);
          setContent("RenderSecurityContent");
        } else {
          setEmail("");
          setWarningText("* This email does not have access to the report.");
        }
        setQueryExecuted(true); // Set to true after successful fetch
      },
      enabled: !!email && !!id && !queryExecuted, // Only enable if email and id are set and query has not been executed
    }
  );

  const {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    openReference,
    citation,
    addCitations,
  } = useOpenReferenceDetails(documents, citations || []);

  const context = generateChatContext(
    documents.map((doc) => doc.document),
    coverageReport
  );

  useCopilotReadable({
    description: "all documentations",
    value: context,
  });

  const policyDetails = getDocumentDetails(documents, "policy");
  const factDetails = getDocumentDetails(documents, "fact");

  console.log("Policy File Name: ", policyDetails.fileName);
  console.log("Policy Presigned URL: ", policyDetails.presignedUrl);
  console.log("Fact File Name: ", factDetails.fileName);
  console.log("Fact Presigned URL: ", factDetails.presignedUrl);

  const initialMessage = generateInitialMessage(
    reportType,
    from,
    followOnQuestions
  );

  return (
    <div
      id="report-container"
      className="mx-auto flex w-full max-w-[100vw] flex-col gap-4 lg:flex-row"
    >
      <div id="document-container" className="w-full lg:w-[60vw]">
        <PDFViewerDialog
          open={isDocViewerOpen}
          doc={document}
          initialPage={initialPage}
          setDocViewerState={setDocViewerState}
          citation={citation}
        />

        <div className="mx-auto w-full">
          <ReportTabs
            reportName={reportName}
            fullCoverageReport={coverageReport}
            docs={documents}
            openReference={openReference}
            reportChatMessages={reportChatMessages}
            reportType={reportType as ReportType}
            documents={documents}
            artifacts={artifacts}
            reportId={id}
          />
          <EmailReportDisclaimer
            setContent={setContent}
            content={content}
            locked={locked}
            isDisclaimerOpen={isDisclaimerOpen}
            setIsDisclaimerOpen={setIsDisclaimerOpen}
            onClose={() => console.log("Disclaimer closed")}
            unlock={unlock}
            warningText={warningText}
            processingStatus={processingStatus}
          />
        </div>
      </div>

      <div
        id="chat-container"
        className="flex h-[calc(100vh-4rem)] w-full flex-col overflow-hidden lg:w-[40vw]"
      >
        <CopilotKit url={`${import.meta.env.VITE_COPILOT_API_URL}/api/copilot`}>
          <CustomChat
            context={context}
            initialMessage={initialMessage}
            reportId={id}
            documents={documents}
            userId={userId}
            reportKey={reportKey}
            openReference={openReference}
            reportSource="email_report"
            appendToReportChatMessages={appendToReportChatMessages}
            oId={oId}
            addCitations={addCitations}
          />
        </CopilotKit>
      </div>
    </div>
  );
};

export default GenReportPublic;
