import React from "react";
import { ArtifactFetchResponse } from "~/api/query_fns/coverage-analysis";
import { chartTypeComponentMapping } from "~/api/query_fns/coverage-analysis";
import { ChartContext } from "../CustomMarkdown";

interface GenQumisInsightsProps {
  artifacts: ArtifactFetchResponse[];
  reportId?: string;
  documentId?: string;
}

const GenQumisInsights: React.FC<GenQumisInsightsProps> = ({
  artifacts,
  reportId,
  documentId,
}) => {
  console.log(JSON.stringify(artifacts));
  return (
    <ChartContext.Provider
      value={{
        reportId,
        documentId,
      }}
    >
      {artifacts
        .sort((a, b) => {
          const order = Object.keys(chartTypeComponentMapping);
          return order.indexOf(a.chartType) - order.indexOf(b.chartType);
        })
        .map((artifact, index) => {
          const Component = chartTypeComponentMapping[artifact.chartType];
          return Component ? <Component key={index} /> : null;
        })}
    </ChartContext.Provider>
  );
};

export default GenQumisInsights;
