import { useContext } from "react";
import { useQuery } from "react-query";
import { ChartType, fetchArtifact } from "../api/query_fns/coverage-analysis";
import { ChartContext } from "../components/CustomMarkdown";

export function useChartData<T>(chartType: ChartType) {
  const { reportId, documentId } = useContext(ChartContext);

  return useQuery<T>(
    ["artifact", documentId, reportId, chartType],
    () =>
      fetchArtifact({
        documentId,
        reportId,
        chart_type: chartType,
      }) as Promise<T>,
    {
      enabled: !!documentId || !!reportId,
    }
  );
}
