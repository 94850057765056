import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { BaseChartComponent } from "./BaseChartComponent";
import { PolicyProvider } from "~/api/query_fns/coverage-analysis";

interface RadarChartData {
  aspect: string;
  [provider: string]: string | number;
  fullMark: number;
  [providerReason: `${string}Reason`]: string;
}

// Add color constants and theme configuration
const CHART_THEME = {
  colors: {
    text: {
      primary: "#2C3E50",
      secondary: "#7F8C8D",
      tooltip: "#34495E",
    },
    background: {
      tooltip: "rgba(255, 255, 255, 0.98)",
      hover: "rgba(236, 240, 241, 0.8)",
      highlight: "rgba(245, 247, 250, 0.95)",
    },
    border: "#E0E6ED",
    shadow: "rgba(0, 0, 0, 0.1)",
  },
  spacing: {
    tooltip: "16px",
    legend: "24px",
  },
  animation: {
    duration: "0.3s",
  },
} as const;

// Extract styles to avoid duplication
const sidePanelStyles = {
  backgroundColor: CHART_THEME.colors.background.tooltip,
  padding: "10px",
  border: `1px solid ${CHART_THEME.colors.border}`,
  borderRadius: "12px",
  width: "550px",
  height: "100%",
  fontSize: "12px",
  lineHeight: "1.6",
  overflowY: "auto",
  // boxShadow: `0 8px 24px ${CHART_THEME.colors.shadow}`,
  color: CHART_THEME.colors.text.tooltip,
  marginRight: "15px",
} as const;

const titleStyles = {
  margin: "15px 0 16px",
  color: CHART_THEME.colors.text.primary,
  fontSize: "16px",
  fontWeight: 700,
  borderBottom: `1px solid ${CHART_THEME.colors.border}`,
  paddingBottom: "12px",
  letterSpacing: "-0.01em",
} as const;

interface SidePanelProps {
  selectedAspect: string | null;
  data: RadarChartData[];
  colors: string[];
  selectedProviders: string[];
}

const SidePanel: React.FC<SidePanelProps> = ({
  selectedAspect,
  data,
  colors,
  selectedProviders,
}) => {
  if (!selectedAspect || !data.length) return null;

  const selectedData = data.find((item) => item.aspect === selectedAspect);
  if (!selectedData) return null;

  const providers = Object.keys(selectedData).filter(
    (key) =>
      key !== "aspect" &&
      key !== "fullMark" &&
      !key.endsWith("Reason") &&
      (selectedProviders.length === 0 || selectedProviders.includes(key))
  );

  return (
    <div style={sidePanelStyles}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ ...titleStyles, margin: 0, borderBottom: "none" }}>
          {selectedAspect}
        </h2>
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke={CHART_THEME.colors.text.secondary}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ cursor: "pointer" }}
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="16" x2="12" y2="12"></line>
          <line x1="12" y1="8" x2="12.01" y2="8"></line>
        </svg>
      </div>
      <div
        style={{
          borderBottom: `1px solid ${CHART_THEME.colors.border}`,
          marginBottom: "16px",
          paddingBottom: "12px",
        }}
      />
      <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        {providers.map((provider, index) => {
          const description = selectedData[`${provider}Reason`];
          const value = selectedData[provider];
          /*           const wrappedReason = reason
            .toString()
            .split(/(.{200}[\s])/g)
            .filter(Boolean)
            .join("\n"); */

          return (
            <div
              key={`panel-${index}`}
              style={{
                padding: "12px",
                borderRadius: "8px",
                backgroundColor: CHART_THEME.colors.background.highlight,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginBottom: "8px",
                }}
              >
                <span
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: colors[index],
                    display: "inline-block",
                    boxShadow: `0 0 0 2px ${CHART_THEME.colors.background.tooltip}`,
                  }}
                />
                <span
                  style={{
                    color: colors[index],
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {provider}
                </span>
                <span
                  style={{
                    marginLeft: "auto",
                    backgroundColor: colors[index],
                    color: "white",
                    padding: "2px 8px",
                    borderRadius: "12px",
                    fontSize: "13px",
                    fontWeight: 600,
                  }}
                >
                  {value}
                </span>
              </div>
              <p
                style={{
                  margin: "0",
                  whiteSpace: "pre-wrap",
                  color: CHART_THEME.colors.text.secondary,
                  fontSize: "13px",
                  lineHeight: "1.6",
                  paddingLeft: "18px",
                }}
              >
                {description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const FullPanel: React.FC<SidePanelProps> = ({
  data,
  colors,
  selectedProviders,
}) => {
  if (!data.length) return null;

  return (
    <div style={{ ...sidePanelStyles, width: "100%" }} className="mt-20">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ ...titleStyles, margin: 0, borderBottom: "none" }}>
          Features
        </h2>
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke={CHART_THEME.colors.text.secondary}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ cursor: "pointer" }}
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="16" x2="12" y2="12"></line>
          <line x1="12" y1="8" x2="12.01" y2="8"></line>
        </svg>
      </div>
      <div
        style={{
          borderBottom: `1px solid ${CHART_THEME.colors.border}`,
          marginBottom: "16px",
          paddingBottom: "12px",
        }}
      />
      <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        {data.map((aspectData, aspectIndex) => {
          const providers = Object.keys(aspectData).filter(
            (key) =>
              key !== "aspect" &&
              key !== "fullMark" &&
              !key.endsWith("Reason") &&
              (selectedProviders.length === 0 ||
                selectedProviders.includes(key))
          );

          return (
            <div key={`aspect-${aspectIndex}`}>
              <h3 style={{ ...titleStyles, margin: "10px 0" }}>
                {aspectData.aspect}
              </h3>
              {providers.map((provider, index) => {
                const reason = aspectData[`${provider}Reason`];
                const value = aspectData[provider];

                return (
                  <div
                    key={`panel-${aspectIndex}-${index}`}
                    style={{
                      padding: "12px",
                      borderRadius: "8px",
                      backgroundColor: CHART_THEME.colors.background.highlight,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: colors[index],
                          display: "inline-block",
                          boxShadow: `0 0 0 2px ${CHART_THEME.colors.background.tooltip}`,
                        }}
                      />
                      <span
                        style={{
                          color: colors[index],
                          fontWeight: 600,
                          fontSize: "15px",
                        }}
                      >
                        {provider}
                      </span>
                      <span
                        style={{
                          marginLeft: "auto",
                          backgroundColor: colors[index],
                          color: "white",
                          padding: "2px 8px",
                          borderRadius: "12px",
                          fontSize: "13px",
                          fontWeight: 600,
                        }}
                      >
                        {value}
                      </span>
                    </div>
                    <p
                      style={{
                        margin: "0",
                        whiteSpace: "pre-wrap",
                        color: CHART_THEME.colors.text.secondary,
                        fontSize: "13px",
                        lineHeight: "1.6",
                        paddingLeft: "18px",
                      }}
                    >
                      {reason}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export interface PolicyEvaluation {
  provider: string;
  categories: Category[];
  total_score: number;
  recommendation: "High" | "Moderate" | "Low";
}

export interface Category {
  name: string;
  value: number;
  description: string;
  components?: Component[];
  maxPoints: number;
}

export interface Component {
  name: string;
  score: number;
  description: string;
}

const transformData = (data: PolicyEvaluation[]): RadarChartData[] => {
  // Return empty array if data is null/undefined
  if (!data || !Array.isArray(data)) {
    console.warn("Invalid data provided to transformData:", data);
    return [];
  }

  console.log("Insurance Policy Data:", JSON.stringify(data, null, 2));

  const intermediateDataState: PolicyProvider[] = data.map((evaluation) => {
    console.log("Processing evaluation:", evaluation);

    const categories = evaluation.categories.map((category) => {
      console.log("Processing category:", category);

      const componentValues =
        category.components?.map((component) => component.score) || [];
      const componentDescriptions =
        category.components?.map((component) => `• ${component.description}`) ||
        [];

      const totalValue = componentValues.reduce((acc, val) => acc + val, 0);
      const concatenatedDescription = componentDescriptions.join("\n");

      console.log("Total value:", totalValue);
      console.log("Concatenated description:", concatenatedDescription);

      return {
        name: category.name,
        value: totalValue,
        value_formatted: totalValue.toString(),
        description: concatenatedDescription,
        max_points: category.maxPoints,
      };
    });

    return {
      provider: evaluation.provider.split(" ").slice(0, 3).join(" "),
      features: categories,
    };
  });

  console.log(
    "Intermediate data state:",
    JSON.stringify(intermediateDataState, null, 2)
  );

  const transformedData: RadarChartData[] = [];

  // Get all unique aspects from all providers
  const aspects = Array.from(
    new Set(
      intermediateDataState.flatMap((provider) =>
        (provider?.features || []).map((feature) => feature.name)
      )
    )
  );

  aspects.forEach((aspect) => {
    const item: RadarChartData = { aspect, fullMark: 100 };
    intermediateDataState.forEach((provider) => {
      if (provider?.features) {
        const featureData = provider.features.find((f) => f.name === aspect);
        item[provider.provider] = featureData?.value || 0;
        if (featureData?.max_points) {
          item[provider.provider] = featureData
            ? Math.round((featureData.value / featureData.max_points) * 100)
            : 0;
        }
        item[`${provider.provider}Reason`] = featureData?.description || "";
      }
    });
    transformedData.push(item);
  });

  return transformedData;
};

// Improve tick rendering with proper types
interface CustomTickProps {
  payload: { value: string };
  x: number;
  y: number;
  textAnchor: string;
  stroke: string;
}

// Enhanced tick rendering with animations
const CustomTick: React.FC<CustomTickProps> = ({
  payload,
  x,
  y,
  textAnchor,
  stroke,
}) => {
  const words = payload.value.split(" ");
  const midpoint = Math.ceil(words.length / 2);
  const line1 = words.slice(0, midpoint).join(" ");
  const line2 = words.slice(midpoint).join(" ");

  return (
    <g>
      <text
        x={x}
        y={y}
        textAnchor={textAnchor}
        stroke={stroke}
        fontSize={12}
        fill="#666"
      >
        <tspan x={x} dy="-0.5em">
          {line1}
        </tspan>
        <tspan x={x} dy="1.2em">
          {line2}
        </tspan>
      </text>
    </g>
  );
};

// Add new styles for the average score card
const averageScoreCardStyles = {
  display: "flex",
  gap: "8px",
  marginBottom: "24px",
  width: "100%",
  padding: "8px",
} as const;

const providerScoreCardStyles = {
  flex: 1,
  padding: "12px",
  borderRadius: "12px",
  backgroundColor: CHART_THEME.colors.background.tooltip,
  border: `1px solid ${CHART_THEME.colors.border}`,
  boxShadow: `0 4px 12px ${CHART_THEME.colors.shadow}`,
  margin: "8px",
  transformOrigin: "center",
} as const;

// Update radio button styles
const radioButtonStyles = {
  position: "absolute" as const,
  top: "12px",
  right: "12px",
  width: "24px",
  height: "24px",
  cursor: "pointer",
  opacity: 0, // Hide the default radio button
  zIndex: 2,
} as const;

// Add custom radio styles
const customRadioStyles = {
  position: "absolute" as const,
  top: "12px",
  right: "12px",
  width: "24px",
  height: "24px",
  borderRadius: "6px",
  border: `2px solid ${CHART_THEME.colors.border}`,
  backgroundColor: CHART_THEME.colors.background.tooltip,
  transition: "all 0.2s ease",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
} as const;

// Add utility function near the top of the file, after interfaces
const calculateProviderAverages = (data: RadarChartData[]) => {
  const providers = Object.keys(data[0] || {}).filter(
    (key) => key !== "aspect" && key !== "fullMark" && !key.endsWith("Reason")
  );

  return providers.map((provider) => {
    const scores = data.map((item) => Number(item[provider]));
    const average = Math.round(
      scores.reduce((a, b) => a + b, 0) / scores.length
    );
    return { provider, average };
  });
};

// Update AverageScoresCard to use the utility function
const AverageScoresCard: React.FC<{
  data: RadarChartData[];
  colors: string[];
  onProviderHover: (provider: string | null) => void;
  selectedProviders: string[];
  onProviderSelect: (provider: string) => void;
}> = ({
  data,
  colors,
  onProviderHover,
  selectedProviders,
  onProviderSelect,
}) => {
  const [hoveredProvider, setHoveredProvider] = React.useState<string | null>(
    null
  );
  const averages = calculateProviderAverages(data);

  return (
    <div style={averageScoreCardStyles}>
      {averages.map((item, index) => (
        <div
          key={item.provider}
          style={{
            ...providerScoreCardStyles,
            cursor: "pointer",
            transform:
              selectedProviders.includes(item.provider) ||
              hoveredProvider === item.provider
                ? "scale(1.05)"
                : "scale(1)",
            position: "relative",
            borderColor: selectedProviders.includes(item.provider)
              ? colors[index]
              : CHART_THEME.colors.border,
            zIndex:
              selectedProviders.includes(item.provider) ||
              hoveredProvider === item.provider
                ? 2
                : 1,
            boxShadow:
              hoveredProvider === item.provider
                ? `0 8px 24px ${CHART_THEME.colors.shadow}`
                : providerScoreCardStyles.boxShadow,
          }}
          onMouseEnter={() => {
            setHoveredProvider(item.provider);
            onProviderHover(item.provider);
          }}
          onMouseLeave={() => {
            setHoveredProvider(null);
            onProviderHover(null);
          }}
        >
          <input
            type="checkbox"
            style={radioButtonStyles}
            checked={selectedProviders.includes(item.provider)}
            onChange={() => onProviderSelect(item.provider)}
            onClick={(e) => e.stopPropagation()}
          />
          <div
            style={{
              ...customRadioStyles,
              backgroundColor: selectedProviders.includes(item.provider)
                ? colors[index]
                : CHART_THEME.colors.background.tooltip,
              borderColor: selectedProviders.includes(item.provider)
                ? colors[index]
                : CHART_THEME.colors.border,
            }}
          >
            {selectedProviders.includes(item.provider) && (
              <svg width="14" height="14" viewBox="0 0 24 24" fill="none">
                <path
                  d="M20 6L9 17L4 12"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "8px",
            }}
          >
            <span
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: colors[index],
                display: "inline-block",
              }}
            />
            <span
              style={{
                color: CHART_THEME.colors.text.primary,
                fontWeight: 600,
                fontSize: "15px",
              }}
            >
              {item.provider}
            </span>
          </div>
          <div
            style={{
              fontSize: "24px",
              fontWeight: 700,
              color: colors[index],
            }}
          >
            {item.average}
          </div>
          <div
            style={{
              fontSize: "13px",
              color: CHART_THEME.colors.text.secondary,
            }}
          >
            QumisScore®
          </div>
        </div>
      ))}
    </div>
  );
};

const ChartRenderer: React.FC<{
  chartData: RadarChartData[];
  colors: string[];
  showLegend: boolean;
}> = ({ chartData, colors }) => {
  const [selectedAspect, setSelectedAspect] = React.useState<string | null>(
    chartData.length > 0 ? chartData[0].aspect : null
  );
  const [hoveredProvider, setHoveredProvider] = React.useState<string | null>(
    null
  );
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>(
    Object.keys(chartData[0] || {}).filter(
      (key) => key !== "aspect" && key !== "fullMark" && !key.endsWith("Reason")
    )
  );

  const handleProviderSelect = (provider: string) => {
    setSelectedProviders((prev) => {
      if (prev.includes(provider)) {
        return prev.filter((p) => p !== provider);
      }
      return [...prev, provider];
    });
  };

  const visibleProviders = hoveredProvider
    ? [hoveredProvider]
    : selectedProviders.length > 0
    ? selectedProviders
    : Object.keys(chartData[0] || {}).filter(
        (key) =>
          key !== "aspect" && key !== "fullMark" && !key.endsWith("Reason")
      );

  const averages = React.useMemo(
    () => calculateProviderAverages(chartData),
    [chartData]
  );

  return (
    <div style={{ width: "100%" }}>
      <AverageScoresCard
        data={chartData}
        colors={colors}
        onProviderHover={setHoveredProvider}
        selectedProviders={selectedProviders}
        onProviderSelect={handleProviderSelect}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "650px",
          maxHeight: "650px",
          alignItems: "top",
        }}
      >
        {/*                    */}

        <SidePanel
          selectedAspect={selectedAspect}
          data={chartData}
          colors={colors}
          selectedProviders={selectedProviders}
        />
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart
            cx="50%"
            cy="50%"
            outerRadius="60%"
            data={chartData}
            margin={{ top: 5, right: 35, left: 25, bottom: 5 }}
            onMouseMove={(data) => {
              if (data.activeLabel) {
                setSelectedAspect(data.activeLabel);
              }
            }}
          >
            <Legend
              layout="horizontal"
              verticalAlign="top"
              align="center"
              wrapperStyle={{ fontSize: "16px" }}
              content={({ payload }) => (
                <ul
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    listStyleType: "none",
                    padding: 0,
                  }}
                >
                  {payload?.map((entry, index) => (
                    <li key={`item-${index}`} style={{ margin: "0 10px" }}>
                      <span style={{ color: entry.color }}>{entry.value}</span>
                    </li>
                  ))}
                </ul>
              )}
            />
            <PolarGrid gridType="polygon" />
            <PolarAngleAxis
              dataKey="aspect"
              tick={CustomTick as any}
              tickLine={true}
              style={{ fontSize: "19px" }}
            />
            <PolarRadiusAxis
              angle={30}
              domain={[0, 100]}
              tick={{ fill: "#666" }}
            />
            {Object.keys(chartData[0] || {})
              .filter(
                (key) =>
                  key !== "aspect" &&
                  key !== "fullMark" &&
                  !key.endsWith("Reason")
              )
              .map((key, index) => {
                const providerAverage =
                  averages.find((avg) => avg.provider === key)?.average ?? 0;
                console.log(`Provider: ${key}, Average: ${providerAverage}`);
                return visibleProviders.includes(key) ? (
                  <React.Fragment key={key}>
                    <Radar
                      name={key}
                      dataKey={key}
                      stroke={colors[index % colors.length]}
                      fill={colors[index % colors.length]}
                      fillOpacity={0.6}
                      animationDuration={300}
                      dot
                      label={(props: LabelProps) => {
                        const { cx, cy, index: pointIndex } = props;
                        if (
                          pointIndex === chartData.length - 1 &&
                          visibleProviders.length === 1
                        ) {
                          return (
                            <g>
                              <circle
                                cx={cx}
                                cy={cy}
                                r={50}
                                fill="white"
                                stroke={colors[index % colors.length]}
                                strokeWidth={2}
                              />
                              <text
                                x={cx}
                                y={cy}
                                textAnchor="middle"
                                fill={colors[index % colors.length]}
                                fontSize={16}
                                fontWeight="bold"
                                dy={-10}
                              >
                                <tspan
                                  x={cx}
                                  dy="0em"
                                >{`${providerAverage}`}</tspan>
                                <tspan x={cx} dy="1.2em" fontSize={12}>
                                  QumisScore®
                                </tspan>
                              </text>
                            </g>
                          );
                        }
                        return null;
                      }}
                    />
                  </React.Fragment>
                ) : null;
              })}
          </RadarChart>
        </ResponsiveContainer>
      </div>

      <FullPanel
        selectedAspect={selectedAspect}
        data={chartData}
        colors={colors}
        selectedProviders={selectedProviders}
      />
    </div>
  );
};

const renderChart = (
  chartData: RadarChartData[],
  colors: string[],
  showLegend: boolean
): JSX.Element => {
  return (
    <ChartRenderer
      chartData={chartData}
      colors={colors}
      showLegend={showLegend}
    />
  );
};

interface InsurancePolicyRadarChartProps {
  showLegend?: boolean;
}

const InsurancePolicyRadarChart: React.FC<InsurancePolicyRadarChartProps> = ({
  showLegend = false,
}) => (
  <BaseChartComponent<PolicyEvaluation, PolicyProvider, RadarChartData>
    title="QumisRank® Snapshot Comparison"
    chartType="artifact_comparison_feature_map"
    transformData={transformData}
    renderChart={(data, colors) => renderChart(data, colors, showLegend)}
  />
);

export default InsurancePolicyRadarChart;

// Add type for label props
interface LabelProps {
  cx: number;
  cy: number;
  value: number | string;
  index: number;
}
