import { ChartResponse, ChartType } from "~/api/query_fns/coverage-analysis";
import { useChartData } from "~/hooks/useChartData";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/ui/accordion";
import { useEffect, useState } from "react";

// Updated with muted, shadcn-style colors
export const CHART_COLORS = [
  "hsl(210, 30%, 25%)", // Darker muted blue
  "hsl(200, 35%, 35%)", // Darker muted teal
  "hsl(190, 30%, 45%)", // Darker soft cyan
  "hsl(180, 25%, 30%)", // Darker muted green
  "hsl(170, 30%, 40%)", // Darker soft green
  "hsl(160, 35%, 50%)", // Darker light muted green
];

export interface BaseChartProps<D, U> {
  title: string;
  chartType: ChartType;
  transformData: (data: D[]) => U[];
  renderChart: (
    chartData: U[],
    colors: string[],
    showLegend?: boolean
  ) => JSX.Element;
}

export function BaseChartComponent<D, T, U>({
  title,
  chartType,
  transformData,
  renderChart,
}: BaseChartProps<D, U>) {
  const {
    data: response,
    isLoading,
    error,
    refetch,
  } = useChartData<ChartResponse<T>>(chartType);

  const [artifactStatus, setArtifactStatus] = useState(response?.status);

  useEffect(() => {
    if (response?.status === "started") {
      setArtifactStatus("started");
    }
  }, [response]);

  useEffect(() => {
    if (artifactStatus === "started") {
      const intervalId = setInterval(() => {
        refetch().then((newResponse) => {
          const newStatus = newResponse.data?.status;
          setArtifactStatus(newStatus);
          if (newStatus === "failed" || newStatus === "complete") {
            clearInterval(intervalId);
          }
        });
      }, 15000); // 15 seconds

      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
      }, 600000); // 10 minutes

      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [artifactStatus, refetch]);

  if (isLoading || artifactStatus === "started") {
    return (
      <div className="my-4">
        <em>Generating {title}</em>
        <span className="animate-blink"> ...</span>
      </div>
    );
  }

  if (error || !response || response.status === "failed") {
    return <div>Error loading data</div>;
  }

  // Extract the data array from the response
  const chartData = transformData(response.data as D[]);

  if (chartData.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <Accordion
      type="single"
      defaultValue="chart"
      collapsible
      className="border-none"
    >
      <AccordionItem value="chart" className="border-none">
        <AccordionTrigger>{title}</AccordionTrigger>
        <AccordionContent>
          {renderChart(chartData, CHART_COLORS)}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
